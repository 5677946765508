// 专项考试列表
<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">考试管理</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">专项考试</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox" style="margin-bottom: 5px">
            <div title="考试名称" class="searchboxItem ci-full">
              <span class="itemLabel">考试名称:</span>
              <el-input
                v-model="examName"
                type="text"
                size="small"
                clearable
                placeholder="请输入考试名称"
              />
            </div>
            <div title="开始时间" class="searchboxItem ci-full">
              <span class="itemLabel">开始时间:</span>
              <el-date-picker
                size="small"
                v-model="startTime"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </div>
            <div title="结束时间" class="searchboxItem ci-full">
              <span class="itemLabel">结束时间:</span>
              <el-date-picker
                size="small"
                v-model="endTime"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </div>
            <div title="考试状态" class="searchboxItem ci-full">
              <span class="itemLabel">考试状态:</span>
              <el-select
                clearable
                size="small"
                v-model="examState"
                placeholder="请选择考试状态"
              >
                <el-option
                  v-for="item in examStateList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
            <div class="btnBox" style="margin-left: 20px">
              <el-button
                style="margin-left: 20px"
                class="bgc-bv"
                round
                @click="getData()"
                >查询</el-button
              >
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                width="100px"
                :index="indexMethod"
              />
              <el-table-column
                label="考试名称"
                align="left"
                prop="examName"
                show-overflow-tooltip
                min-width="150"
              />
              <el-table-column
                label="开始时间"
                align="left"
                prop="startDate"
                show-overflow-tooltip
                min-width="150"
              />
              <el-table-column
                label="结束时间"
                align="center"
                show-overflow-tooltip
                prop="endDate"
                min-width="150"
              >
              </el-table-column>
              <el-table-column
                label="考试状态"
                align="left"
                prop="compName"
                show-overflow-tooltip
                min-width="100"
              >
                <template slot-scope="scope">
                  {{ $setDictionary("EXAM_STATE", scope.row.examState) }}
                </template>
              </el-table-column>
              <el-table-column
                label="应考人数"
                align="left"
                prop="createTime"
                show-overflow-tooltip
                min-width="100"
              >
                <template slot-scope="scope">
                  {{ scope.row.testNumber || "--" }}
                </template>
              </el-table-column>
              <el-table-column
                label="实考人数"
                align="left"
                prop="createTime"
                show-overflow-tooltip
                min-width="100"
              >
                <template slot-scope="scope">
                  {{ scope.row.realTestNumber || "--" }}
                </template>
              </el-table-column>
              <el-table-column
                label="操作"
                align="center"
                width="100px"
                fixed="right"
              >
                <div slot-scope="scope" class="flexcc">
                  <el-button
                    type="text"
                    style="padding: 0px 5px"
                    size="mini"
                    @click="handleDetail(scope.row.examId)"
                    >考试详情</el-button
                  >
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
  </div>
</template>
<script>
import QRCode from "qrcodejs2"; // 引入qrcode
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
// import tree from "@/components/treePopup";
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "JGspecialTestList",
  components: {
    Empty,
    PageNum,
    // tree,
  },
  mixins: [List],
  data() {
    return {
      //
      areaId: "",
      examName: "",
      examState: "",
      startTime: "",
      endTime: "",
      createTime: "",
      //
      examStateList: [], //
      ruleForm: {
        Trainingtype: "",
      },
      params: {},
      // 导学员参数
      totalNum: "0",
      errorNum: "0",
      correctNum: "0",
      fileName: "", // 导入学员文件名
      fileKey: "", // 导入学员文件的id
      centerDialogVisible: false,
      // 考试二维码
      examStartTime: "",
      examEndTime: "",
      examTime: "",
      examTimeValue: "",
      examAddress: "",
      examNum: "",
      examNumValue: "",
      totalScore: "",
      passScore: "",
      examAuthentication: "",
      examDescription: "",
      examNameDialog: "",
      questionNum: "",
      //
      exportTempStu: "10",
    };
  },
  computed: {},
  created() {
    this.getSelect();
    this.getTableHeight();
  },
  methods: {
    // 培训类型回调
    childBack(params) {
      this.params = { ...params };
    },
    clearParams() {
      this.params = {};
    },
    // 获取字典 -- 考试状态
    getSelect() {
      const listObj = this.$setDictionary("EXAM_STATE", "list");
      this.examStateList = [];
      for (const key in listObj) {
        this.examStateList.push({
          value: key,
          label: listObj[key],
        });
      }
    },
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      if (this.areaId) {
        params.areaId = this.areaId;
      }
      if (this.examName) {
        params.examName = this.examName;
      }
      if (this.examState) {
        params.examState = this.examState;
      }
      if (this.startTime && this.startTime.length) {
        params.startStartDate = this.startTime[0];
        params.startEndDate = this.startTime[1];
      }
      if (this.endTime && this.endTime.length) {
        params.endStartDate = this.endTime[0];
        params.endEndDate = this.endTime[1];
      }
      if (this.createTime && this.createTime.length) {
        params.startCreateTime = this.createTime[0];
        params.endCreateTime = this.createTime[1];
      }
      if (this.params.trainFormId) {
        params.trainTypeId = this.params.trainFormId;
      }
      if (this.params.levelFormId) {
        params.trainLevelId = this.params.levelFormId;
      }
      if (this.params.occFormId) {
        params.occupationId = this.params.occFormId;
      }
      if (this.params.postFormId) {
        params.postId = this.params.postFormId;
      }
      if (this.params.industryFormId) {
        params.industryId = this.params.industryFormId;
      }
      this.doFetch({
        url: "/biz/exam/governmentPageList",
        params,
        pageNum,
      });
    },
    handleDetail(examId){
      this.$router.push({
        path:'/web/JGspecialTestDetail',
        query:{
          examId
        }
      })
    },
    //
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 1 + 1.35 ) * 16;
      if (opDom) {
        tHeight -= 40 + 40 + 0.675 * 16 ;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
  },
  beforeRouteLeave: resetKeepAlive,
  watch: {
    $route: {
      handler: function (val, oldVal) {},
      // 深度观察监听
      deep: true,
    },
  },
};
</script>
<style lang="less"></style>

